.pagination {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}
.pagination > li {
  margin: 0 12px;
  list-style: none;
}
.pagination > li > a {
  position: relative;
  font-size: 1rem;
  z-index: 100;
  cursor: pointer;
}
.pagination > li > a::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: -100;
  transition: all 0.3s ease;
}
.pagination > li > a:hover::before {
  background-color: rgba(144, 205, 244, 0.5);
}
.pagination > li.active > a::before {
  background-color: rgba(144, 205, 244, 0.5);
}
.pagination__previous, .pagination__next {
  padding-top: 2px;
}
.pagination__previous > a, .pagination__next > a {
  font-size: 0.7rem;
  z-index: 999;
}
.pagination__disabled {
  color: #c0c0c0;
  pointer-events: none;
}

_::-webkit-full-page-media,
_:future,
:root .pagination__previous__link::before,
.pagination__next__link::before {
  margin-top: 8px;
  margin-left: 8px;
}/*# sourceMappingURL=style.css.map */