.pagination {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;

    & > li {
        margin: 0 12px;
        list-style: none;
        & > a {
            position: relative;
            font-size: 1.0rem;
            z-index: 100;
            cursor: pointer;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 24px;
                height: 24px;
                border-radius: 50%;
                z-index: -100;
                transition: all .3s ease;
            }

            &:hover {
                &::before {
                    background-color: rgba(144, 205, 244, 0.5);
                }
            }
        }

        &.active {
            & > a::before {
                background-color: rgba(144, 205, 244, 0.5);
            }
        }
    }


    &__previous,
    &__next {
        padding-top: 2px;
        & > a {
            font-size: 0.7rem;
            z-index: 999;
        }
    }

    &__disabled {
        // display: none;
        color: #c0c0c0;
        pointer-events: none;
    }
}

_::-webkit-full-page-media,
_:future,
:root .pagination__previous__link::before,
.pagination__next__link::before  {
    margin-top: 8px;
    margin-left:8px;
}
